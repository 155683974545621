import PropTypes from "prop-types";
import { graphql, Link } from "gatsby";
import React from "react";
import styled from "styled-components";
import { media } from "utils/Media";
import MainNav from "components/functional/mainNav";
import SingleNews from "components/functional/singleNews";
import SmallTitle from "components/functional/smallTitle";
import Layout from "components/layout";
import ReactPlayer from "react-player";
import { StaticImage } from "gatsby-plugin-image";

const metaData = {
  title: "What Would Beyoncé Do? | D E W Y N T E R S",
  description:
    "What Would Beyoncé Do? What Queen Bee can teach us about pricing in the theatre industry.",
  image: "images/news/cover.png",
};

const NewsBody = styled.section`
  width: 100%;

  .news {
    width: 85%;
    margin: var(--auto);
    max-width: 900px;
    @media ${media.sm} {
      padding-top: 2rem;
    }
  }

  .app-img {
    margin-bottom: 1rem;
  }
`;
const SingleNewsStyles = styled.article`
  position: relative;
  width: 100%;
  margin-bottom: 4rem;

  .gatsby-image-wrapper {
    width: 100%;
    height: auto;
  }

  svg {
    width: 30px;
    height: 30px;
    fill: var(--cyan);
    margin-bottom: 0.5rem;
  }

  h1 {
    font-weight: 800;
    font-size: 1.5rem;

    span {
      color: var(--cyan);
    }
    @media ${media.md} {
      font-size: 33px;
    }
  }

  .body {
    margin-top: 1rem;
    a {
      text-decoration: underline 2px;
      color: #09dbd8 !important;
      &:hover,
      &:active {
        transform: scale(1.5);
      }
    }
    p {
      margin-bottom: 1rem;

      strong {
        font-weight: 900;
        font-family: var(--PoppinsBold);
      }

      span,
      a {
        color: var(--cyan);
        font-weight: 800;
      }
    }
  }

  @media ${media.sm} {
    .gatsby-image-wrapper {
      height: auto;
    }
  }
  @media ${media.xl} {
    .gatsby-image-wrapper {
      height: auto;
    }
  }
`;
const LatestNews = ({ data }) => {
  return (
    <Layout
      title={metaData.title}
      description={metaData.description}
      image={metaData.image}
    >
      {/* <SmallTitle title="What Would Beyoncé Do?" dnd/> */}
      <MainNav alt />
      <NewsBody>
        <div className="news">
          <SingleNewsStyles>
            <div className="header">
              <StaticImage
                src="../../images/news/Beyonce/cover.png"
                alt="What Would Beyoncé Do?"
                className="app-img"
              />
              <h1>
                What Would Beyoncé Do? What Queen Bee can teach us about pricing
                in the theatre industry.
              </h1>
            </div>
            <div className="body">
              <p>
                Soaring demand for hot-ticket festivals, gigs and, of course,
                West End shows are sparking a spending boom &ndash; despite the
                cost-of-living crisis. Customers joining the queue to secure
                tickets to Beyonc&eacute;&rsquo;s Renaissance tour, were
                informed they were 234,835<sup>th</sup> in the queue.
              </p>
              <p>
                Live music has been a hot topic recently, both in the{" "}
                <a href="https://www.bbc.co.uk/news/entertainment-arts-64372274">
                  headlines
                </a>{" "}
                and on social media. In October, Glastonbury Festival startled
                fans by announcing a 26% increase in their ticket price &ndash;
                up to &pound;340 &ndash; and this week has seen some backlash to
                the prices for Beyonc&eacute;&rsquo;s tour. Similar to dynamic
                ticket pricing in the West End,{" "}
                <strong>
                  surge pricing &ndash; the act of selling tickets at a price
                  that fluctuates with demands &ndash;
                </strong>{" "}
                has become more common with high profile artists such as{" "}
                <a href="https://www.forbes.com/sites/conormurray/2023/01/31/elton-johns-tour-became-the-highest-grossing-ever-heres-where-others-u2-taylor-swift-rolling-stones-rank/?sh=298c80106782">
                  Taylor Swift
                </a>
                .
              </p>
              <p>
                Even outside of surge pricing,{" "}
                <strong>
                  77% of the British public believe the price to see live music
                  is too expensive
                </strong>
                .
              </p>
              <p>
                <strong>Value perception</strong> is of course completely
                dependent on a person&rsquo;s situation and their interests.
                Whilst{" "}
                <strong>
                  53% said a fair price for a ticket would be &pound;40 or less
                </strong>
                , the majority of gig-goers
                <strong> have paid at least twice as much, </strong>with 37%
                having paid over &pound;100 a ticket and 9% over &pound;200.
                This shows that for the right event, people are willing to pay
                premium prices.
              </p>
              <StaticImage
                src="../../images/news/Beyonce/graph-1.png"
                alt="What Would Beyoncé Do?"
                className="app-img"
              />
              <p>
                With the combination of the cost-of-living crisis, rising
                prices, and higher student loan repayments,{" "}
                <strong>
                  younger people are generally more price-sensitive
                </strong>{" "}
                and savvier about{" "}
                <a href="https://www.ft.com/youngmoney">
                  finding ways to save money
                </a>
                . This is particularly evident in the growing popularity of
                FinTok &ndash; practical personal finance TikToks &ndash; that
                are teaching young people how to make their money go further.
                Content tagged with #personalfinance has garnered more than
                4.4bn views.
              </p>
              <p>
                However, in the latest research by YouGov we are actually seeing
                that young people are willing to pay premium pricing for gigs.{" "}
                <strong>
                  18-24 year olds are the age group most likely to view
                  &pound;100 or more as fair for a live concert ticket,{" "}
                </strong>
                and are the most supportive of surge pricing for concert
                tickets.
              </p>
              <StaticImage
                src="../../images/news/Beyonce/graph-2.png"
                alt="What Would Beyoncé Do?"
                className="app-img"
              />
              <p>
                Across many of our campaigns however, we see that audiences over
                the age of 45 will generally purchase at a higher average ticket
                price. For one show in particular{" "}
                <strong>
                  the average ticket price for audiences over the age of 45 was
                  14% higher than those under the age of 45.
                </strong>
              </p>
              <p>So whilst life stage can impact value perception of an event, when comparing pricing levels in live music and theatre we know that one of the main factors is the simple economic principle of <strong>supply and demand</strong>. Particularly in the case of long-runners, where there is confidence that the show is here to stay, potential customers are more likely to be able to find a price point they are comfortable with if they are willing to purchase further out. In the Stage&rsquo;s 2022 Ticketing Survey,<strong> the average cheapest ticket price for musicals was &pound;23.92 and for plays was &pound;20.92</strong>. The same cannot be said for the other live entertainment events where tickets can sell a lot quicker due to <strong>scarcity of supply</strong>, and <strong>hot demand</strong>.</p>
              <p>
                However, although this gives the impression that theatre
                audiences are more price-sensitive and that their value
                perception is lower, this isn&rsquo;t necessarily the case. The
                Stage&rsquo;s annual ticketing survey reported that{" "}
                <strong>
                  the average cost of the most expensive tickets available for
                  West End productions is &pound;140.85, up 21.3% on 2019
                </strong>
                . Whilst the average top price for long-running shows has fallen
                since 2019, the average has been brought up significantly by
                newer kids on the block, with one show raising some eyebrows for
                utilising dynamic pricing to the tune of &pound;400.
              </p>
              <p>
                Whatever your viewpoint on those price points is, it does prove
                that theatre holds the potential to be the &ldquo;hot
                ticket&rdquo; in town and can in fact command higher prices in
                the same way as a high-profile artist.
              </p>
              <p>
                <strong>
                  So how do we, as marketeers, create hype and heat around a
                  production to keep audiences engaged, and the demand for
                  tickets high? We put it to the team at Dewynters for their
                  views!
                </strong>
              </p>
              <p>
                <strong>
                  Richard McDermott, Deputy Director of Creative Services
                </strong>
              </p>
              <p>
                <em>
                  Theatre brands need to create an emotional connection but
                  increasingly, they also need to behave with the confidence and
                  clarity traditionally associated with the live music industry.
                  Bold messaging and a strong audience focus have always been a
                  hallmark of music industry advertising but advances in
                  audience insights and creative testing are now becoming
                  invaluable tools in building more responsive and impactful
                  theatre brands. Pushing forward into spaces not usually
                  occupied by theatre advertising and making bolder and more
                  confident media choices are also increasing the reach and
                  scope of theatrical brands. While there are no magic bullets,
                  ultimately a brand that looks and behaves like a &lsquo;hot
                  ticket&rsquo; has a greater chance of being one.
                </em>
              </p>
              <p>
                <strong>Paul Goodman, Social Media Director </strong>
              </p>
              <p>
                <em>
                  From the comments and questions we receive each day via social
                  media we see more and more that audiences are expecting
                  theatre productions to behave like live music and comedy gigs.
                  Before tickets are on sale, audiences are asking for dates,
                  venue and pricing, as well as recommendations for where they
                  can eat and stay when they visit the theatre. They want to
                  book their annual leave and check the cost of travel before
                  they even have a ticket. This is especially true for fans of
                  household name musicals and bookers who are hoping to see
                  their favourite screen star on stage.
                </em>
              </p>
              <p>
                <strong>Anne Ewart, Managing Director </strong>
              </p>
              <p>
                <em>
                  As personal finances are increasingly squeezed, live
                  entertainment is having to work hard to reassure that a night
                  (or afternoon) out is going to be great value &ndash; no
                  matter whether your budget is &pound;25, &pound;75 or
                  &pound;175+ for a ticket. Creating hype around a production or
                  event is key to this reassurance.&nbsp;The exciting
                  opportunity for all theatre marketeers is how we can learn
                  from the good and the bad of large-scale music industry
                  on-sales: how can we optimise the way we speak to our
                  audiences via creative, social, PR, media, insights and sales
                  channels?&nbsp;By building positive hype around new and
                  longstanding productions we tempt the fans and broader
                  audiences to behave outside their normal patterns.&nbsp;Even
                  in these stretched financial times we are still seeing that
                  with the right hype, people have little resistance to when
                  they&rsquo;ll attend an event or spend on a ticket!
                </em>
              </p>
            </div>
            <p>
              <strong>
                <Link to="/opinion/">{`< Back`}</Link>
              </strong>
            </p>
          </SingleNewsStyles>
        </div>
      </NewsBody>
    </Layout>
  );
};

LatestNews.propTypes = {
  data: PropTypes.shape({
    News: PropTypes.any,
  }),
};

export default LatestNews;
